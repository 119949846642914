<template>
  <div id="graphdiv" class="graph">
    
    <el-row type="flex" class="row-bg">
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <!-- <el-select v-model="codeOrNm" slot="append" placeholder="请选择" > -->
          <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
          <div >
            <el-input placeholder="请输入内容" v-model="codeOrNm" class="input-with-select" size="mini">
              <el-select v-model="codeOrNm" slot="prepend" placeholder="请选择" >
                <el-option  :key='item.code' v-for="(item) in selectOption"  :label='item.code +" " + item.name' :value='item.code' ></el-option>
              </el-select>
            </el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="grid-content bg-purple-light">
          <div class="block">
            <el-date-picker
              v-model="dayValue"
              type="daterange"
              align="right"
              unlink-panels
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              size="mini"
            ></el-date-picker>
            <el-button type="primary" plain @click="getGraph" size="mini">确定</el-button>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="11">
        <div id="mychart1" :style="{width:'600px',height:'260px'}"></div>
      </el-col>
      <el-col :span="6">
        <div id="mychart3" :style="{width:'600px',height:'260px'}"></div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="11">
        <div id="mychart2" :style="{width:'600px',height:'260px'}"></div>
      </el-col>
       <el-col :span="6">
        <div id="mychart4" :style="{width:'600px',height:'260px'}"></div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "Gpgraph",
  data() {
    return {
      codeOrNm: "000636",
      selectText:"风华高科(000636)",
      selectOption: [],
      startDate: "",
      endDate: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            }
          },
                {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365*2);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value1: "",
      dayValue: [new Date().getNextDate(-30), new Date().format("yyyy-MM-dd")],

      maxPriceCoord: null,
      minPriceCoord: null,
      intervalCoord: null,
      xdataline: [],
      ydataline: [],
      xdatacol1: [],
      ydatacol1: [],
      xdatacol2: [],
      ydatacol2: [],
      xdatacol3: [],
      ydatacol3: []
    };
  },
  mounted() {
    this.getSelection();
    this.getLineData();
  },

  methods: {
    getSelection(){
        this.$axios.post("/showwy/selection")
            .then(res => {
                this.selectOption = res.data.data
                // console.log( "****************getSelection: res1 ****************");
                // this.selectText =  this.selectOption[0].name + " - " + this.selectOption[0].code
                // console.log(this.selectOption[0].name)
                // console.log(this.selectOption)
        });
    },

    getLineData() {
            for (let index = 0; index <  this.selectOption.length; index++) {
                const element = this.selectOption[index].code;
                if (this.codeOrNm === element) {
                    this.selectText =  this.selectOption[index].name + "(" +this.codeOrNm + ")";
                }
            }
        
            this.$axios.post("/showwy/linedata", {
                codeOrNm: this.codeOrNm,
                startDate: this.startDate,
                endDate: this.endDate
                })
                .then(res => {
                this.xdataline = res.data.data.tdateLst;
                this.ydataline = res.data.data.closePriceLst;
                this.xdatacol1 = res.data.data.tdateLst;
                this.ydatacol1 = res.data.data.cjjeLst;
                this.xdatacol2 = res.data.data.tdateLst;
                this.ydatacol2 = res.data.data.cjlLst;
                this.xdatacol3 = res.data.data.tdateLst;
                this.ydatacol3 = res.data.data.zdfLst;
                // console.log( "***********linedata: res1   getLineData()**********");
                // console.log(this.xdataline);
                // console.log(this.ydataline);

                let maxClsPrcie = 0;
                let minClsPrcie = parseFloat(this.ydataline[0]);
                for (let index = 0; index < this.ydataline.length; index++) {
                    const clsPrice = parseFloat(this.ydataline[index]); 
                    if (clsPrice>maxClsPrcie) {
                      maxClsPrcie = clsPrice;
                    }
                    if (clsPrice<minClsPrcie) {
                      minClsPrcie = clsPrice;
                    }
                }
                this.maxPriceCoord = (maxClsPrcie*(1+0.05)).toFixed(2);
                this.minPriceCoord = (minClsPrcie*(1-0.05)).toFixed(2);
                this.intervalCoord = parseFloat(((this.maxPriceCoord - this.minPriceCoord)/6).toFixed(2));

                this.drawLineChart();
                // this.$message({
                //     showClose: true,
                //     message: '恭喜你，操作成功',
                //     type: 'success',
                //     onClose: () => {
                //         this.getHqhisList()
                //     }
                // });
                });
       
    },

    drawLineChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("mychart1"));
      let myChart2 = this.$echarts.init(document.getElementById("mychart2"));
      let myChart3 = this.$echarts.init(document.getElementById("mychart3"));
      let myChart4 = this.$echarts.init(document.getElementById("mychart4"));
      // 绘制图表
      // #region 折线图
      myChart1.setOption({
        title: [
            {
                top: '5%',
                left: 'center',
                text: this.selectText +"- ClosePrice"
            } 
        ],
        tooltip: {
             trigger: 'axis'
        },
        xAxis: {
          type: "category",
          data: this.xdataline
        },
        yAxis:  [
                    {
                      min: this.minPriceCoord, //y轴的最小值
                      max: this.maxPriceCoord, //y轴最大值
                      interval: this.intervalCoord, //值之间的间隔
                      splitLine:{show:true},
                      type: 'value',
                      name: "价格(元)",
                      // axisLabel: {
                      //     formatter:'{value}千万'
                      //   }
                    }
                  ],
        series: [
          {
            data: this.ydataline,
            type: "line",
            itemStyle: {//折线背景色
                normal: {color: "#FF00FF"},
            },
          }
        ]
      });

      // #region 柱状图
      myChart2.setOption({
          title: [
              {
                  top: '5%',
                  left: 'center',
                  text: "CjJe"
              } 
          ],
          tooltip: {
             trigger: 'axis'
          },
        
          xAxis: {
              data: this.xdatacol1
          },
          yAxis:  [
              {
                type: 'value',
                name: "成交金额(亿元)",
              }
            ],
          series: [
              {
                  name: 'je',
                  type: 'bar',
                  data:  this.ydatacol1,
                  itemStyle: {//柱体背景色
                      normal: {
                        color: "#FF7F00",
                      },
                  },
              }
          ]
      });

      // #region 柱状图
      myChart3.setOption({
          title: [
              {
                  top: '5%',
                  left: 'center',
                  text: "CjL"
              } 
          ],
          tooltip: {
             trigger: 'axis'
          },
        
          xAxis: {
              data: this.xdatacol2
            
          },
          yAxis: [
                    {
                      type: 'value',
                      name: "成交量(千万)",
                      // axisLabel: {
                      //     formatter:'{value}千万'
                      //   }
                    }
                  ],
          series: [
              {
                  name: 'cjl',
                  type: 'bar',
                  data:  this.ydatacol2, 
                  // formatter: '{ this.ydatacol2} qw'
                  itemStyle: {//柱体背景色
                      normal: {
                        color: "#7B68EE",
                      },
                  },
              }
          ]
      });

      // #region 柱状图
      myChart4.setOption({
          title: [
              {
                  top: '5%',
                  left: 'center',
                  text: "zdf"
              } 
          ],
          tooltip: {
             trigger: 'axis'
          },
        
          xAxis: {
              data: this.xdatacol3
            
          },
          yAxis: [
                    {
                      type: 'value',
                      name: "涨跌幅(%)",
                    }
                  ],
          series: [
              {
                  name: 'zdf',
                  type: 'bar',
                  data:  this.ydatacol3,
                  // data:  this.ydatacol3.map((item) => {
                  //     // 循环 
                  //     return {
                  //       value: item,
                  //       label: {
                  //         show: true, //开启显示
                  //         position: item > 0 ? "top" : "bottom", //判断正负值上方显示还是下方
                  //         textStyle: {
                  //           //数值样式
                  //           color: item < 0 ? "#229D45" : "#E93030",   //数值颜色
                  //           fontSize: "14px",
                  //         },
                  //       },
                  //     };
                  //   }),

                  itemStyle: {//柱体背景色
                      normal: {
                        color: (val) => {
                          var index_color = val.value;
                          // return index_color > 0 ? "#E93030" : "#229D45";
                          return index_color > 0 ? "red" : "green";
                        },
                      },
                  },

                  // formatter: '{ this.ydatacol2} qw'
              }
          ]
      }); 


    },
    
    getGraph() {
      this.startDate = this.dayValue[0];
      this.endDate = this.dayValue[1];
      // console.log("****************getGraph()****************")
      // console.log(this.codeOrNm)
      // console.log(this.startDate)
      // console.log(this.endDate)
      this.getLineData();
    }
  }
};
</script>


<style scoped>
  .graph{
      height: 1400px ;
  }

    .el-select .el-input {
        width: 130px;
    }
    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }
    
    .el-button--primary {
    margin-left: 10px;
    }

    .el-row {
    margin: 2px;
    /* &:last-child {
        margin-bottom: 0;
        } */
    }

    .el-col {
    border-radius: 4px;
    margin-left: 5px;
    }

    .bg-purple-dark {
    background: #99a9bf;
    }

    .bg-purple {
    background: #d3dce6;
    }

    .bg-purple-light {
    background: #e5e9f2;
    }

    .grid-content {
    border-radius: 4px;
    min-height: 26px;
    }

    .row-bg {
    padding: 2px 0;
    background-color: #f9fafc;
    }
</style>